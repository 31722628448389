import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=041c5c12&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041c5c12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/Users/macssd/WorkFolder/corrent_project/online_kassa/nuxtjsagr_frontend/components/Footer.vue').default})
